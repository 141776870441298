<template>
  <div class="leads-table-area campaigns-list-area">
    <router-link to="/add-new-campaign" class="rem-btn add-campaign-list-btn"
      ><img class="img-fluid" src="../../assets/img/23. Close.svg" alt="" /> Add
      New Campaign</router-link
    >
    <table id="campaigns_table" class="display">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Subject</th>
          <th>Recepients</th>
          <th>Status</th>
          <th>Created</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>

    <!-- Campaign Detail Modal -->
    <div
      class="modal fade"
      id="campaignDetailModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="previousLeadsLabel">Campaign</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="details-modal">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Title</td>
                        <td>{{ campaign_detail.ec_name }}</td>
                      </tr>
                      <tr>
                        <td>Subject</td>
                        <td>{{ campaign_detail.ec_subject }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12">
                <div class="notes-con" v-if="campaign_detail.ec_template">
                  <h3>Template</h3>
                  <iframe
                    id="myframe"
                    width="100%"
                    height="400px"
                    :srcdoc="campaign_detail.ec_template"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal33"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-xl"
        :class="filteredCustomers.length > 10 ? '' : 'modal-dialog-centered'"
        role="document"
      >
        <div class="modal-content preview-modal">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              List Generator Preview
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <!-- <div class="download-links">
                    <ul>
                        <li>Print</li>
                        <li>
                            <a href="#" v-on:click="downloadIt('print')" ><img class="img-fluid print-icon" src="../../assets/img/print.svg" alt=""></a>
                        </li>
                        <li>Download</li>
                        <li>
                            <a href="#" v-on:click="downloadIt('img')"><img class="img-fluid" src="../../assets/img/pdf.svg" alt=""></a>
                        </li>
                        <li>
                            <a href="#" v-on:click="downloadIt('pdf')"><img class="img-fluid" src="../../assets/img/pdfp.svg" alt=""></a>

                        </li>
                    </ul>
                </div> -->
          <div class="modal-body">
            <!-- leads-table-area  -->
            <div
              class="leads-table-area addcustomer-table addcampaigm-table"
              id="download_area"
              v-show="loaded"
            >
              <table class="display add_campaign_list_table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <!-- <th>Sales Type</th>
                                    <th>Source</th>
                                    <th>Status</th>
                                    <th>Assigned</th>
                                    <th>Email Address</th>
                                    <th>Address</th> -->
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(cdata, index) in filteredCustomers" :key="index">
                    <td>#{{ cdata.c_id }}</td>
                    <td>{{ cdata.c_first_name }} {{ cdata.c_last_name }}</td>
                    <td>{{ cdata.c_email }}</td>
                    <td>{{ cdata.c_phone }}</td>
                    <td>
                      {{ cdata.c_city }} {{ cdata.c_state }} {{ cdata.c_zip }}
                    </td>
                  </tr>

                  <!-- <tr>
                                    <td>1</td>
                                    <td>Mehdi Hasan</td>
                                    <td>Purchase</td>
                                    <td>Internet</td>
                                    <td>Sales Rep</td>
                                    <td><a class="solds" href="#">Active</a></td>
                                    <td><img class="img-fluid chek-cl" src="../../assets/img/checks.svg" alt=""></td>
                                    <td>Inglewood, Maine 98380</td>
                                </tr> -->
                </tbody>
              </table>
            </div>
            <div v-show="!loaded" class="text-center">
              <span class="spinner-border spinner-border-sm"></span> Loading...
            </div>
            <!-- leads-table-area  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.min";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";

export default {
  name: "campaigns",
  data() {
    return {
      campaign_detail: {},
      filteredCustomers: [],
      loaded: false,
      pdf: require("../../assets/img/pdfp.svg"),
      csv: require("../../assets/img/csv.svg"),
      print: require("../../assets/img/print.svg"),
    };
  },
  methods: {
    changeCampaignStatus(id, status) {
      axios
        .post(
          "change_campaign_list_status",
          { id: id, status: status },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            if (status == 1) {
              $("#statusChange" + id).addClass("assign");
            } else {
              //
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    viewCampaign(id) {
      $("#campaignDetailModal").modal("show");
      axios
        .get("campaign_detail/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.campaign_detail = res.data.success;
          } else {
            this.campaign_detail = {};
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    $("#campaigns_table")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: axios.defaults.baseURL + "dt_campaigns",
          type: "POST",
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        },
      });
    let vm = this;
    $("tbody").on("click", ".clStatus", function () {
      var id = $(this).data("id");
      var status = $(this).data("status");
      vm.changeCampaignStatus(id, status);
    });
    $("tbody").on("click", ".clView", function () {
      var id = $(this).data("id");
      vm.viewCampaign(id);
    });
    $("tbody").on("click", ".recepients", function () {
      var id = $(this).data("id");
      $(".add_campaign_list_table").dataTable().fnDestroy();
      axios
        .get("getCampaignsRecipents/" + id, {
          headers: {
            Authorization: "Bearer " + vm.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            vm.filteredCustomers = data.success;
            //this.loaded = true
            setTimeout(() => {
              $(".add_campaign_list_table")
                .addClass("nowrap")
                .dataTable({
                  dom: "Blfrtip",
                  buttons: [
                    {
                      extend: "pdfHtml5",
                      text:
                        '<img class="img-fluid" src="' + vm.pdf + '" alt="">',
                      className: "btn_pdf",
                      titleAttr: "PDF",
                    },

                    {
                      extend: "csvHtml5",
                      text:
                        '<img style="width: 66px;" class="img-fluid" src="' +
                        vm.csv +
                        '" alt="">',
                      titleAttr: "CSV",
                      className: "btn_csv",
                    },

                    {
                      extend: "print",
                      text:
                        '<img style="width: 42px;" class="img-fluid" src="' +
                        vm.print +
                        '" alt="">',
                      title: "",
                      className: "btn_print",
                    },
                  ],
                  responsive: true,
                  fixedHeader: true,
                  paging: false,
                  ordering: false,
                  info: false,
                });
              vm.loaded = true;
              $("#exampleModal33").modal("show");
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
};
</script>

<style scoped>
@import "../../assets/css/dataTables.css";
.add-campaign-list-btn {
  position: absolute;
  right: 12px;
  top: 13px;
  z-index: 1;
}
.campaigns-list-area {
  position: relative;
}
.modal-dialog {
  max-width: 919px;
}
</style>
<style>
.campaigns-list-area .dataTables_filter {
  margin-right: 222px;
}

#exampleModal33 .dataTables_wrapper .dataTables_filter {
  left: 454px;
  position: relative;
}
</style>